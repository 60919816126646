import { styled } from "styled-components";

const TopDiv = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: end;
`;

const Menu = styled.div`
    display: flex;
    margin: 0 auto;
`;

const MenuItem = styled.div`
    text-align: center;
    padding: 0 1rem;
`;

const Top = ({ showSingers, showSongs }) => {
    return (
        <TopDiv>
            <Menu>
                <MenuItem onClick={showSingers}>خواننده‌ها</MenuItem>
                <MenuItem onClick={showSongs}>آهنگ‌ها</MenuItem>
            </Menu>
        </TopDiv>
    );
}

export default Top;