import React from 'react';
import { styled } from 'styled-components';
import { FaHome, FaUser, FaMusic, FaUserFriends } from 'react-icons/fa';

const NavContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
`;

const NavItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: ${props => props.active ? '#1976d2' : '#666'};
    transition: color 0.2s ease;

    &:hover {
        color: #1976d2;
    }
`;

const NavText = styled.span`
    font-size: 12px;
    margin-top: 4px;
`;

const BottomNav = ({ activeTab, onTabChange }) => {
    return (
        <NavContainer>
            <NavItem active={activeTab === 'home'} onClick={() => onTabChange('home')}>
                <FaHome size={20} />
                <NavText>خانه</NavText>
            </NavItem>
            <NavItem active={activeTab === 'songs'} onClick={() => onTabChange('songs')}>
                <FaMusic size={20} />
                <NavText>آهنگ‌ها</NavText>
            </NavItem>
            <NavItem active={activeTab === 'friends'} onClick={() => onTabChange('friends')}>
                <FaUserFriends size={20} />
                <NavText>دوستان</NavText>
            </NavItem>
            <NavItem active={activeTab === 'profile'} onClick={() => onTabChange('profile')}>
                <FaUser size={20} />
                <NavText>پروفایل</NavText>
            </NavItem>
        </NavContainer>
    );
};

export default BottomNav; 