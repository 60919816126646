import React, { useState, useRef, memo } from 'react';
import styled from 'styled-components';

const PlayerDiv = styled.div`
    position: fixed;
    bottom: ${props => props.expandRatio > 0.5 ? '0' : '70px'};
    left: ${props => props.expandRatio > 0.5 ? '0' : '2%'};
    width: ${props => props.expandRatio > 0.5 ? '100%' : '96%'};
    height: ${props => props.height}px;
    background-color: #f58730;
    border-radius: ${props => Math.max(25, 50 - (props.expandRatio * 25))}px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    z-index: 100;
    touch-action: none;
    transition: ${props => props.isDragging ? 'none' : 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)'};
`;

const ContentDiv = styled.div`
    width: 100%;
    display: flex;
    flex-grow: 1;
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    flex-direction: ${props => props.expandRatio > 0.5 ? 'column' : 'row'};
    align-items: ${props => props.expandRatio > 0.5 ? 'center' : 'center'};
    justify-content: ${props => props.expandRatio > 0.5 ? 'flex-start' : 'space-between'};
    padding: ${props => props.expandRatio > 0.5 ? `${props.expandRatio * 40}px 0 0 0` : '0'};
`;

const ImageDiv = styled.div`
    width: ${props => 40 + (props.expandRatio * 240)}px;
    min-width: ${props => 40 + (props.expandRatio * 240)}px;
    height: ${props => 40 + (props.expandRatio * 240)}px;
    border-radius: ${props => 40 - (props.expandRatio * 20)}px;
    background-color: white;
    margin: ${props => props.expandRatio > 0.5 ? '0 auto' : '9px'};
    overflow: hidden;
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
`;

const TextDiv = styled.div`
    height: ${props => props.expandRatio > 0.5 ? 'auto' : '100%'};
    overflow: hidden;
    flex-grow: ${props => props.expandRatio > 0.5 ? '0' : '1'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.4rem;
    color: white;
    text-align: ${props => props.expandRatio > 0.5 ? 'center' : 'right'};
    margin: ${props => props.expandRatio > 0.5 ? '20px 0' : '0 10px'};
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    width: ${props => props.expandRatio > 0.5 ? '100%' : 'auto'};
    direction: ${props => props.expandRatio > 0.5 ? 'ltr' : 'rtl'};
`;

const TitleDive = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.expandRatio > 0.5 ? '2' : '1'};
    line-clamp: ${props => props.expandRatio > 0.5 ? '2' : '1'}; 
    -webkit-box-orient: vertical;
    font-size: ${props => 1 + (props.expandRatio * 0.5)}rem;
    margin-bottom: ${props => props.expandRatio * 10}px;
    transition: all 0.1s linear;
    direction: rtl;
`;

const SingerDiv = styled.div`
    font-size: ${props => 0.6 + (props.expandRatio * 0.4)}rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
    opacity: ${props => 1 - (props.expandRatio * 0.2)};
    transition: all 0.1s linear;
    direction: rtl;
`;

const ButtonDiv = styled.div`
    width: ${props => props.expandRatio > 0.5 ? '100%' : 'auto'};
    margin: ${props => props.expandRatio > 0.5 ? '30px 5px' : '0 10px'};
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: ${props => props.expandRatio > 0.5 ? 'center' : 'flex-end'};
    transition: all 0.1s linear;
    min-width: 120px;

    svg {
        margin: ${props => props.expandRatio > 0.5 ? '0 20px' : '0 5px'};
        transform: scale(${props => 1.3 + (props.expandRatio * 0.5)});
    }
`;

const TimeLineDiv = styled.div`
    width: calc(100% - ${props => 50 + (props.expandRatio * 50)}px);
    height: ${props => 3 + (props.expandRatio * 0.2)}px;
    margin: ${props => props.expandRatio > 0.5 ? '20px auto' : 'auto'};
    border-radius: 10px;
    background-color: #d4f4fc;
    transition: all 0.1s linear;
`;

const TimeLineBarBase = styled.div`
    height: 100%;
    background-color: #f58730;
    border-radius: 10px;
    float: left;
    transition: all 180ms linear;
`;

const TimeLineBar = ({style}) => (
    <TimeLineBarBase style={style} />
);

const MemoizedTimeLineBar = memo(TimeLineBar);
const MemoizedImage = memo(({songId}) => (
    <img width="100%" src={`https://media1.hamahangim.com/img/song/${songId}.jpg`} alt="song cover" />
));

const MemoizedButtons = memo(({expandRatio, isPlaying, playAudio, pauseAudio, playNextSong, playPreviousSong}) => (
    <ButtonDiv expandRatio={expandRatio}>
        <svg
            width="30"
            height="16"
            fill="white"
            viewBox="0 0 16 16"
            onClick={playNextSong}
        >
            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
            <path d="m16.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
        </svg>

        <svg
            width="35"
            height="16"
            fill="white"
            viewBox="0 0 16 16"
            onClick={() => (isPlaying ? pauseAudio() : playAudio())}
        >
            {isPlaying ? (
                <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"/>
            ) : (
                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
            )}
        </svg>

        <svg
            width="30"
            height="16"
            fill="white"
            viewBox="0 0 16 16"
            onClick={playPreviousSong}
        >
            <path d="M4.404 7.303l6.363-3.692c.54-.313 1.233.066 1.233.697v7.384c0 .63-.692 1.01-1.233.696L4.404 8.697a.802.802 0 0 1 0-1.393z"/>
            <path d="M.404 7.303l6.363-3.692c.54-.313 1.233.066 1.233.697v7.384c0 .63-.692 1.01-1.233.696L.404 8.697a.802.802 0 0 1 0-1.393z"/>
        </svg>
    </ButtonDiv>
));

const MemoizedText = memo(({expandRatio, songName, singerName}) => (
    <TextDiv expandRatio={expandRatio}>
        <TitleDive expandRatio={expandRatio}>{songName}</TitleDive>
        <SingerDiv expandRatio={expandRatio}>{singerName}</SingerDiv>
    </TextDiv>
));

const Player = memo(({ isPlaying, playAudio, pauseAudio, myTimeLine, friendTimeLine, songName, singerName, songId, playNextSong, playPreviousSong }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [expandRatio, setExpandRatio] = useState(0);
    const [height, setHeight] = useState(64);
    const playerRef = useRef(null);
    const dragStartY = useRef(0);
    const currentHeight = useRef(64);
    const maxHeight = window.innerHeight - 60;
    const minHeight = 64;

    const calculateExpandRatio = (height) => {
        return Math.min(Math.max((height - minHeight) / (maxHeight - minHeight), 0), 1);
    };

    const handleTouchStart = (e) => {
        setIsDragging(true);
        dragStartY.current = e.touches[0].clientY;
        currentHeight.current = playerRef.current.offsetHeight;
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;

        const touch = e.touches[0];
        const deltaY = dragStartY.current - touch.clientY;
        let newHeight;

        newHeight = currentHeight.current + deltaY;

        newHeight = Math.max(minHeight, Math.min(maxHeight, newHeight));
        
        setHeight(newHeight);
        setExpandRatio(calculateExpandRatio(newHeight));
    };

    const handleTouchEnd = (e) => {
        setIsDragging(false);
        
        const currentRatio = calculateExpandRatio(height);
        
        if (isExpanded) {
            if (currentRatio < 0.9) {
                setIsExpanded(false);
                setExpandRatio(0);
                setHeight(minHeight);
            } else {
                setExpandRatio(1);
                setHeight(maxHeight);
            }
        } else {
            if (currentRatio > 0.1) {
                setIsExpanded(true);
                setExpandRatio(1);
                setHeight(maxHeight);
            } else {
                setExpandRatio(0);
                setHeight(minHeight);
            }
        }
    };

    return (
        <PlayerDiv 
            ref={playerRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            expandRatio={expandRatio}
            isDragging={isDragging}
            height={height}
        >
            <TimeLineDiv expandRatio={expandRatio}>
                <MemoizedTimeLineBar style={{ width: `${myTimeLine}%` }} />
            </TimeLineDiv>
            <ContentDiv expandRatio={expandRatio}>
                <ImageDiv expandRatio={expandRatio}>
                    <MemoizedImage songId={songId} />
                </ImageDiv>
                <MemoizedText 
                    expandRatio={expandRatio}
                    songName={songName}
                    singerName={singerName}
                />
                <MemoizedButtons 
                    expandRatio={expandRatio}
                    isPlaying={isPlaying}
                    playAudio={playAudio}
                    pauseAudio={pauseAudio}
                    playNextSong={playNextSong}
                    playPreviousSong={playPreviousSong}
                />
            </ContentDiv>
            <TimeLineDiv expandRatio={expandRatio}>
                <MemoizedTimeLineBar style={{ width: `${friendTimeLine}%` }} />
            </TimeLineDiv>
        </PlayerDiv>
    );
});

export default Player;