import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "styled-components";
import Song from './Song.js';

const Main = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    padding: 10px 0 70px 0;
    display: flex;
    float: right;
    flex-wrap: wrap;
    overflow: auto;
`;

const SongsContainer = ({ songs, changeSong }) => {
    return (
        <Main>
            {songs.map((song) => (
                <Song key={song.id} song={song} changeSong={changeSong} />
            ))}
        </Main>
    );
}

export default SongsContainer;