import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "styled-components";
import Collection from './Collection.js';

const Main = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    padding: 10px 0 70px 0;
    display: flex;
    float: right;
    flex-wrap: wrap;
    overflow: auto;
`;

const CollectionsContainer = ({ singers, getSongs }) => {
    return (
        <Main>
            {singers.map((singer) => (
                <Collection key={singer.id} data={singer} getSongs={getSongs} />
            ))}
        </Main>
    );
}

export default CollectionsContainer;