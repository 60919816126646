import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "styled-components";
import Player from './Player.js';
import CollectionsContainer from './CollectionsContainer.js';
import SongsContainer from './SongsContainer.js';
import Top from './Top.js';
import Notification from './Notification'
import BottomNav from './BottomNav';

const MainView = styled.div`
    width: 100%;
    height: calc(100vh - 160px);
    background-color: white;
    border-radius: 25px 25px 0 0;
    box-shadow: 0 0 1px #d0d0d0;
    padding: 40px 0 140px 0;
    overflow: hidden;
`;

const All = styled.div`
    width: 200%;
    height: calc(100vh - 160px);
    background-color: white;
    overflow: auto;

    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
`;

const Main = () => {

  const [singers, setSingers] = useState([]);
  const [songs, setSongs] = useState([]);
  const [playList, setPlayList] = useState([]);
  const [allMarginRight, setAllMarginRight] = useState('0');
  const [audio, setAudio] = useState(new Audio());
  const [media, setMedia] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [count, setCount] = useState(0);
  const [myTimeLine, setMyTimeLine] = useState(0);
  const [friendTimeLine, setFriendTimeLine] = useState(0);

  const [songId, setSongId] = useState(0);
  const [singerId, setSingerId] = useState(0);
  const [songName, setSongName] = useState('');
  const [singerName, setSingerName] = useState('');
  const [waitForUpdateResponse, setWaitForUpdateResponse] = useState(false);
  const [startAt, setStartAt] = useState(0);

  const [activeTab, setActiveTab] = useState('home');

  // const [notification, setNotification] = useState({ title: '', body: '' });
  // useEffect(() => {
  //   requestPermission();
  //   const unsubscribe = onMessageListener().then((payload) => {
  //     console.log(payload?.notification?.title);
  //     setNotification({
  //       title: payload?.notification?.title,
  //       body: payload?.notification?.body,
  //     });
  //     toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
  //       duration: 10000,
  //       position: 'top-right',
  //     });
  //   }); 
  //   return () => {
  //     unsubscribe.catch((err) => console.log('failed: ', err));
  //   };
  // }, [notification]);

  audio.onplay = () => {
    setIsPlaying(true);
  }

  audio.onpause = () => {
    setIsPlaying(false);
  }

  audio.ontimeupdate = () => {
    setMyTimeLine(audio.currentTime / audio.duration * 100);
  }

  audio.onended = () => {
    playNextSong();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      setMyTimeLine(audio.currentTime / audio.duration * 100);

      updateStatus();
    }, 500);

    return () => clearInterval(interval);
  }, [count]);

  const updateStatus = async () => {
    if (waitForUpdateResponse) {
      return;
    }

    try {
      setWaitForUpdateResponse(true);
      const formData = new FormData();
      formData.append('songId', songId);
      formData.append('singerId', singerId);
      formData.append('currentTime', audio.currentTime);
      
      const response = await axios.post('/broadcast/status', formData);
      if (response.data.status === 'Success') {
        const data = response.data.data;
        if (data.song) {
          setSong(data.song);
          setPlayList(JSON.parse(data.playList));
        }
        if (data.friendStatus) {
          setFriendTimeLine(data.friendStatus.currentTime / audio.duration * 100);
        }
      }
      
      setWaitForUpdateResponse(false);
    } catch (error) {
      setWaitForUpdateResponse(false);
    }
  }

  const setSong = (song) => {
    setStartAt(song.startAt * 1000);
    if (song.id !== songId) {
      setMyTimeLine(0);
    }
    setSongId(song.id);
    setSongName(song.songName);
    setSingerName(song.singerName);
    setMedia(`https://media1.hamahangim.com/songs/128/${song.id}.mp3`);
  }

  const getData = async () => {
    try {
      const response = await axios.post('/singers');
      console.log(response.data.data.list);
      setSingers(response.data.data.list);
    } catch (error) {
      //   console.error(error);
    }
  };

  const getSongs = async (singerId) => {
    try {
      if (singerId > 0) {
        showSongs();
      }
      setSongs([]);

      const formData = new FormData();
      formData.append('pageNumber', 1);
      formData.append('singerId', singerId);
      
      const response = await axios.post('/songs', formData);
      // console.log(response.data.data.list);
      setSongs(response.data.data.list);
    } catch (error) {
      //   console.error(error);
    }
  };

  const showSingers = () => {
    setAllMarginRight('0');
  }

  const showSongs = () => {
    setAllMarginRight('-100%');
  }

  const changeSong = async (id, currentPlayList = null) => {
    try {
      const formData = new FormData();
      formData.append('songId', id);
      formData.append('playList', JSON.stringify(currentPlayList ?? songs));
      
      const response = await axios.post('/broadcast/change-song', formData);
    } catch (error) {

    }
  }

  const playAudio = () => {
    console.log(startAt - Date.now());
    setTimeout(() => {
      audio.play().catch((e)=>{});
    }, startAt - Date.now());
  }

  const pauseAudio = () => {
    audio.pause();
  }

  const playNextSong = () => {
    let nextSong = null;
    for (let i=0; i<playList.length; i++) {
      if (playList[i].id === songId) {
        nextSong = playList[i + 1] ?? null;
        break;
      }
    }
    if (nextSong) {
      changeSong(nextSong.id, playList);
    }
  }

  const playPreviousSong = () => {
    let previousSong = null;
    for (let i=0; i<playList.length; i++) {
      if (playList[i]['id'] === songId) {
        previousSong = playList[i - 1] ?? null;
        break;
      }
    }
    if (previousSong) {
      changeSong(previousSong.id, playList);
    }
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'songs') {
      showSongs();
    } else if (tab === 'home') {
      showSingers();
    }
  };

  useEffect(() => {
    getData();
    getSongs();
  }, []);

  useEffect(() => {
    if (media !== '') {
      audio.src = media;
      audio.load();
      playAudio();
    }
  }, [media]);

  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: songName,
        artist: singerName,
        album: "My Album",
        artwork: [
          { src: `https://media1.hamahangim.com/img/song/${songId}.jpg`, sizes: '512x512', type: 'image/jpeg' }
        ]
      });
  
      navigator.mediaSession.setActionHandler('play', playAudio);
      navigator.mediaSession.setActionHandler('pause', pauseAudio);
      navigator.mediaSession.setActionHandler('previoustrack', playPreviousSong);
      navigator.mediaSession.setActionHandler('nexttrack', playNextSong);
      navigator.mediaSession.setActionHandler('seekto', (event) => {
        if (event.fastSeek && audio.fastSeek) {
          audio.fastSeek(event.seekTime);
        } else {
          audio.currentTime = event.seekTime;
        }
      });
    }
  }, [songName, singerName, media]);
  

  return (
    <>
      <Top showSingers={showSingers} showSongs={showSongs} />
      
      <MainView>
        <All style={{marginRight: allMarginRight}}>
          <CollectionsContainer singers={singers} getSongs={getSongs} />
          <SongsContainer songs={songs} changeSong={changeSong}/>
        </All>
      </MainView>
      <Player
        isPlaying={isPlaying}
        playAudio={playAudio}
        pauseAudio={pauseAudio}
        myTimeLine={myTimeLine}
        friendTimeLine={friendTimeLine}
        songName={songName}
        singerName={singerName}
        songId={songId}
        playNextSong={playNextSong}
        playPreviousSong={playPreviousSong}
      />
      <Notification
        setSong={setSong}
      />
      <BottomNav activeTab={activeTab} onTabChange={handleTabChange} />
    </>
  );
}
export default Main;